/* font 설정 */
@font-face {
    font-family: 'SHSN';
    src: url('../fonts/SpoqaHanSansNeo-Thin.otf');
    font-weight: 100, 200;
    font-style: normal;
}
@font-face {
    font-family: 'SHSN';
    src: url('../fonts/SpoqaHanSansNeo-Light.otf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'SHSN';
    src: url('../fonts/SpoqaHanSansNeo-Regular.otf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'SHSN';
    src: url('../fonts/SpoqaHanSansNeo-Medium.otf');
    font-weight: 500, 600;
    font-style: normal;
}
@font-face {
    font-family: 'SHSN';
    src: url('../fonts/SpoqaHanSansNeo-Bold.otf');
    font-weight: 700, 800, 900;
    font-style: normal;
}

* {
    font-family: inherit;
}

html,
body {
    font-family: 'SHSN';
    color: #000000;
    font-size: 62.5%;
    font-weight: 400;
    line-height: 1.285;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0;
}

.active {
    font-weight: 700 !important;
}

.layout-padding {
    padding: 0% 15%;
    box-sizing: border-box;
}

.disabled-link{
    color: #fff;
}