/* 모바일 기기용 스타일 */
@media (max-width: 767px) {
    html,
    body {
        font-size: 42.5% !important;
    }
    .layout-padding {
        padding: 0% 5%;
    }
}

/* 태블릿 기기용 스타일 */
@media (min-width: 768px) and (max-width: 1023px) {
    html,
    body {
        font-size: 52.5% !important;
    }
    .layout-padding {
        padding: 0% 10%;
    }
}

/* 데스크탑용 스타일 */
@media (min-width: 1024px) {
    html,
    body {
        font-size: 62.5% !important;
    }
}
